<template>
  <div class="container">
    <div v-if="flag">
      <div class="search">
        <el-row>
          <el-col :span="4">
            <span>所属项目 : </span>

            <el-select v-model="corporation" placeholder="请选择">
              <el-option
                v-for="item in corporationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <span>任务名称 : </span>
            <el-input v-model="taskname" placeholder="请输入内容"></el-input>
          </el-col>
          <el-col :span="4">
            <span>任务类型 : </span>
            <el-input v-model="tasktype" placeholder="请输入内容"></el-input>
          </el-col>
          <el-col :span="5">
            <span>任务风险等级 : </span>
            <el-select v-model="riskgrade" placeholder="请选择">
              <el-option
                v-for="item in Risklevel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="6">
            <el-button type="primary" @click="getReport">搜索</el-button>
            <el-button type="primary" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="main">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="corporations" align="center" label="所属项目">
          </el-table-column>
          <el-table-column prop="taskname" align="center" label="任务名称">
          </el-table-column>
          <el-table-column prop="tasktype" label="任务类型" align="center">
          </el-table-column>
          <el-table-column prop="riskgrade" label="任务风险等级" align="center">
          </el-table-column>
          <el-table-column prop="details" label="任务详情" align="center">
          </el-table-column>
          <el-table-column prop="standard" label="任务执行标准" align="center">
          </el-table-column>
          <el-table-column label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="primary" @click="toSubmit(scope.row)"
                >提交任务报告</el-button
              >
              <el-button type="primary" @click="getSubmit(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleCurrentChange"
          @current-change="handleSizeChange"
          :current-page="page"
          :page-sizes="[5, 10, 20]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <div class="goback">
        <el-button type="primary" @click="flag = true">返回</el-button>
      </div>
      <el-table :data="reportTableData" style="width: 100%" border stripe>
        <el-table-column prop="executor" align="center" label="执行人姓名">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="执行人手机号">
        </el-table-column>
        <el-table-column prop="timedata" label="实行时间" align="center">
        </el-table-column>
        <el-table-column prop="audits" label="审核状态" align="center">
        </el-table-column>
        <el-table-column prop="auditor" label="审核人" align="center">
        </el-table-column>

        <el-table-column label="操作" width="280px">
          <template slot-scope="scope">
            <el-button type="primary" @click="editSubmit(scope.row)"
              >修改</el-button
            >
            <el-button v-if="ranl" type="primary" @click="auditSubmit(scope.row)"
              >审核报告</el-button
            >
            <el-button type="danger" @click="deleteauditSubmit(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
      @close="closeDialog"
    >
      <el-form
        ref="addForm"
        :model="addFormData"
        label-width="120px"
        :rules="addFormDataRules"
      >
        <el-form-item label="执行人姓名" prop="executor">
          <el-input v-model="addFormData.executor"></el-input>
        </el-form-item>
        <el-form-item label="执行人手机号" prop="phone">
          <el-input v-model="addFormData.phone"></el-input>
        </el-form-item>
        <el-form-item label="实行时间" prop="timedata">
          <el-input v-model="addFormData.timedata"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input v-model="addFormData.describe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHandle">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审核报告" :visible.sync="auditdialogVisible" width="30%">
      <el-form ref="auditform" :model="auditForm" label-width="120px">
        <el-form-item label="审核人">
          <el-input v-model="auditForm.auditor"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="auditForm.types" placeholder="请选择">
            <el-option
              v-for="item in auditOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="未通过原因">
          <el-input v-model="auditForm.cause"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="auditdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAuditReport">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectInfo } from "../../../request/project";
import {
  getReportInfo,
  addReportInfo,
  getdebriefing,
  editdebriefing,
  toSubmitAudit,
  delelteSubmitAudit,
 
} from "../../../request/report";
export default {
  name: "report",
  data() {
    return {
      ranl:true,
      corporationOptions: [], //项目列表
      Risklevel: [
        {
          value: "低风险",
          label: "低风险",
        },
        {
          value: "一般风险",
          label: "一般风险",
        },
        {
          value: "较大风险",
          label: "较大风险",
        },
        {
          value: "重大风险",
          label: "重大风险",
        },
      ], //风险等级
      corporation: "",
      taskname: "",
      tasktype: "",
      riskgrade: "",
      tableData: [],
      page: 1,
      pagesize: 5,
      total: null,
      dialogVisible: false,
      addFormData: {
        hid: "",
        uid: "",
        executor: "",
        phone: "",
        timedata: "",
        describe: "",
      },
      dialogTitle: "提交任务报告",
      flag: true,
      reportTableData: [],
      addFormDataRules: {
        executor: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
      },
      auditdialogVisible: false, //审核报告对话框的显示与隐藏
      auditOptions: [
        {
          value: 1,
          label: "通过",
        },
        {
          value: 2,
          label: "未通过",
        },
      ], //审核状态
      auditForm: {
        id: "",
        types: "",
        auditor: "",
        cause: "",
      }, //审核表单
      hid: {}, //记录查看的任务id
    };
  },
  created() {
    // 获取所有的项目
    // this.getProject();
    // // 获取隐患排查任务
    var add= localStorage.getItem('rank')
    if(add==1){
      this.ranl=true
    }else{
      this.ranl=false
    }
    this.getReport();
   
  },
  mounted() {},
  methods: {
  
    // 删除任务报告
    deleteauditSubmit(row) {
      console.log(row);
      let msg = {
        id: row.id,
      };
      this.$confirm("是否删除此任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delelteSubmitAudit(msg);
          if (res.data.code == 200) {
            this.getSubmit(this.hid);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 提交审核
    async toAuditReport() {
      const res = await toSubmitAudit(this.auditForm);
      //  console.log(res);
      if (res.data.code == 200) {
        this.auditdialogVisible = false;
        this.getSubmit(this.hid);
        this.$message({
          type: "success",
          message: "审核成功",
        });
      }
    },
    // 打开审核报告的对话框
    auditSubmit(row) {
      this.auditdialogVisible = true;
      this.auditForm.id = row.id;
    },
    // 关闭对话框
    closeDialog() {
      this.$refs.addForm.resetFields();
      this.dialogTitle = "提交任务报告"
      // this.addFormData = {
      //         hid: "",
      //   uid: "",
      //   executor: "",
      //   phone: "",
      //   timedata: "",
      //   describe: "",
      // }
    },
    // 修改任务报告
    editSubmit(row) {
      this.dialogVisible = true;
     
      this.$nextTick(() => {
        this.addFormData = JSON.parse(JSON.stringify(row));
      });

      this.dialogTitle = "修改任务报告";
    },
    // 获取提交的任务报告
    async getSubmit(row) {
      //  console.log(row);
      this.hid.id = row.id;
      this.flag = false;
      let msg = {
        hid: row.id,
      };
      const res = await getdebriefing(msg);
      // console.log(res);
      this.reportTableData = res.data;
    },
    // 提交任务报告
    async submitHandle() {
      if (this.flag) {
        //添加任务报告
        const res = await addReportInfo(this.addFormData);
        // console.log(res);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "提交成功",
          });
        }
      } else {
        //修改任务报告
        const res = await editdebriefing(this.addFormData);
        // console.log(res);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.getSubmit(this.hid);
          this.$message({
            type: "success",
            message: "修改成功",
          });
        }
      }
    },
    // 打开提交任务报告对话框
    toSubmit(row) {
      this.addFormData.hid = row.id;
      this.addFormData.uid = row.uid;
      // console.log(row);
      this.dialogVisible = true;
    },
    // 重置
    reset() {
      this.corporation = "";
      this.taskname = "";
      this.tasktype = "";
      this.riskgrade = "";
      this.getReport();
    },

    //   一页显示几条改变
    handleCurrentChange(newVal) {
      this.pagesize = newVal;
      this.getReport();
    },
    //页码改变
    handleSizeChange(newVal) {
      this.page = newVal;
      this.getReport();
    },
    // 获取项目
    async getProject() {
      let msg = {
        corporation: window.localStorage.getItem("companys"),
      };
      const res = await getProjectInfo(msg);

      for (let i = 0; i < res.data.length; i++) {
        let obj = {};
        obj.value = res.data[i].id;
        obj.label = res.data[i].name;
        this.corporationOptions.push(obj);
      }
    },

    //获取隐患排查
    async getReport() {
      let msg = {
        uid: window.localStorage.getItem("uid"),
      };
      const res = await getReportInfo(msg);
      console.log(res);
      this.tableData = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .el-dialog {
    width: 100%;
  }
}
.goback {
  text-align: right;
  margin-bottom: 20px;
}
.search {
  .el-input {
    width: 60%;
  }
  .el-select {
    width: 55%;
  }
}
.main {
  margin-top: 20px;
}
.addDialog {
  .el-input,
  .el-select {
    width: 80%;
  }
}
</style>